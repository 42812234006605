import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"

import { RegisterAccountActivated } from "page_components/login-register"

const RegisterPage = ({ data, location }) => {
  return (
    <Layout
      location={location}
      mainBackground={
        data?.allWpPage?.nodes[0]?.featuredImage?.node?.localFile?.publicURL
      }
    >
      <Breadcrumbs title="Rejestracja" />
      <RegisterAccountActivated />
    </Layout>
  )
}

export const Head = () => <Seo title="Rejestracja" noIndex />

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxMzIxNg==" } }) {
      nodes {
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default RegisterPage
